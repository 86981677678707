<template>
  <div
    class="sp-content-module"
    :class="['theme-' + themeName, { 'is-open-widget': isWidgetOpen }]"
    ref="SPContent"
  >
    <div :class="{ container: themeName === 'oister' }">
      <template v-if="launchWidgetButton">
        <div
          class="sp-content-module_overlay widget-btn-close"
          @click="isWidgetOpen = false"
        />
        <div class="sp-content-module_control">
          <button
            class="show-btn-control"
            @click="isWidgetOpen = !isWidgetOpen"
          >
            <span>{{ widgetButtonName }}</span>
          </button>
        </div>
        <div
          :class="['sp-content-module_widget', { 'widget-open': isWidgetOpen }]"
        >
          <div class="sp-content-module_widget-heder">
            <h1>{{ title || launchWidgetButtonName }}</h1>
            <button
              class="widget-btn-close"
              @click="isWidgetOpen = !isWidgetOpen"
            >
              <span>Close</span>
            </button>
          </div>
          <div
            v-if="isWidgetOpen"
            class="sp-content-module_widget-content"
          >
            <slot> An empty content module </slot>
          </div>
        </div>
      </template>
      <template v-else>
        <slot>An empty content module</slot>
      </template>

      <loader-overlay />
    </div>
  </div>
</template>

<script>
import LoaderOverlay from './LoaderOverlay.vue'

export default {
  props: {
    renderType: {
      type: String,
      default: '1', // '1' as widget; '2' as float button
    },
    buttonText: {
      type: String,
      default: '',
    },
    title: { type: String, default: '' },
  },
  components: { LoaderOverlay },
  data() {
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    const urlParams = new URLSearchParams(window.location.search)
    const isWidgetOpen = urlParams.get('sp-widget-open') === 'true'
    return {
      themeName: themeName || 'generic',
      launchWidgetButton: this.renderType === '2',
      isWidgetOpen,
      launchWidgetButtonName: this.buttonText || 'Show',
    }
  },
  mounted() {
    if (!this.launchWidgetButton) {
      this.$emit('init')
    }
  },
  computed: {
    widgetButtonName() {
      return this.launchWidgetButtonName &&
        this.launchWidgetButtonName.length > 45
        ? this.launchWidgetButtonName.slice(0, 45)
        : this.launchWidgetButtonName
    },
  },
  watch: {
    isWidgetOpen(isOpen) {
      if (isOpen) {
        this.$nextTick(() => {
          this.$emit('init')
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '~/assets/scss/mixins.scss';
@import '~/assets/scss/base.scss';
@include sp-content-module {
  @import '~/assets/scss/bootstrap-import/global-modules.scss';
  @import '~/assets/scss/bundle.scss';
  .modal-backdrop {
    opacity: 0.5;
  }
  .container {
    max-width: 768px;
    margin: auto;
    @media screen and (max-width: 768px) {
      padding: 16px;
    }
  }

  .show-btn-control {
    border: 1px solid $dark;
    border-radius: 0;
  }

  &_widget {
    display: none;
  }
}

@import '~/assets/webfonts/ss-black-tie-light/font.scss';
</style>
