<template>
  <div
    class="image-marker-wrap"
    :class="wrapClasses"
    :style="wrapStyles"
    @mouseleave="onMouseleave"
  >
    <div
      ref="imageMarkerRef"
      class="image-marker"
      :class="classes"
      :style="styles"
      :role="hasAction ? 'button' : undefined"
      @mouseover="onMouseover"
      @click="onClick"
    >
      <div v-if="hasArrow">
        <span class="icon-caret" />
      </div>
    </div>

    <template v-if="hasTooltip && showPopover">
      <div
        ref="popoverRef"
        class="image-marker-popover"
        :class="[popoverPlacement]"
      >
        <div class="image-marker-popover-body">
          <div v-html="tooltipHtml" />
        </div>
        <div class="image-marker-popover-arrow" />
      </div>
    </template>
  </div>
</template>

<script>
import { rgbaToHex } from '~/utils'

export default {
  props: ['data'],
  data() {
    return {
      isTouchScreen: false,

      // popover props
      showPopover: false,
      popoverPlacement: 'bottom',
    }
  },
  mounted() {
    this.isTouchScreen =
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0

    const el = this.$refs.imageMarkerRef
    if (el) {
      const opacity =
        (this.data && this.data.style && this.data.style.opacity) || 0
      el.style.setProperty('--opacity', opacity)
    }

    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  computed: {
    isSquare() {
      const { shape } = this.data || {}
      return shape === 'square'
    },
    isCircle() {
      return !this.isSquare
    },
    hasArrow() {
      const { arrow } = this.data || {}
      return !!(this.isCircle && arrow && arrow !== 'none')
    },
    hasAction() {
      const { action } = this.data || {}
      return action && action.type
    },
    hasTooltip() {
      const { type = undefined, tooltip = '' } =
        (this.data && this.data.action) || {}

      if (!tooltip) return false

      const noAction = !type
      return this.isTouchScreen
        ? noAction && tooltip.length > 0
        : tooltip.length > 0
    },
    wrapStyles() {
      const styles = (this.data && this.data.style) || {}
      return {
        ...styles,
        position: 'absolute',
        transform: this.isCircle
          ? 'translateX(-50%) translateY(-50%)'
          : undefined,
        opacity: undefined, // to override value from 'styles'
        zIndex: this.showPopover ? 1 : undefined,
      }
    },
    wrapClasses() {
      const output = []

      this.hasTooltip && output.push('has-tooltip')

      return output
    },
    styles() {
      const { color } = this.data || {}
      return {
        opacity: 'var(--opacity)',
        backgroundColor:
          typeof color === 'object'
            ? rgbaToHex(color.r, color.g, color.b, color.a)
            : undefined,
      }
    },
    classes() {
      const output = []
      const { shape = 'circle', size, color, animate, arrow } = this.data || {}

      shape && output.push(shape)
      color && typeof color === 'string' && output.push(color)

      if (size) output.push(size)

      if (animate) {
        switch (animate) {
          case 'pulse':
            output.push('pulse')
            break
          case 'fade-in-out':
          case 'opacity': // to handle old articles
            output.push('fade-in-out')
            break
        }
      }

      if (this.isCircle) {
        animate &&
          (animate === 'arrow' || (animate === true && arrow !== 'none')) &&
          output.push('animate') // 'arrow' animation available only for circle shape
        arrow && arrow !== 'none' && output.push('arrow') && output.push(arrow)
      }

      return output
    },
    tooltipHtml() {
      return this.data && this.data.action && this.data.action.tooltip
    },
  },
  methods: {
    onClick() {
      if (this.isTouchScreen && this.hasTooltip && !this.hasAction) {
        this.togglePopover()
      } else {
        this.$emit('click', this.data)
      }
    },
    onMouseover() {
      if (this.isTouchScreen || !this.hasTooltip) return

      this.togglePopover(true)
    },
    onMouseleave() {
      if (this.isTouchScreen || !this.hasTooltip) return

      this.togglePopover(false)
    },

    // popover methods
    togglePopover(value = undefined) {
      this.showPopover =
        typeof value !== 'undefined' ? value : !this.showPopover

      if (this.showPopover) {
        this.determinePlacement()
      }
    },
    determinePlacement() {
      const trigger = this.$refs.imageMarkerRef
      const triggerRect = trigger.getBoundingClientRect()

      const spaceAbove = triggerRect.top
      const spaceBelow = window.innerHeight - triggerRect.bottom
      const spaceLeft = triggerRect.left
      const spaceRight = window.innerWidth - triggerRect.right

      const spaces = {
        top: spaceAbove,
        right: spaceRight,
        bottom: spaceBelow,
        left: spaceLeft,
      }

      this.popoverPlacement = Object.keys(spaces).reduce(
        (best, key) => (spaces[key] > spaces[best] ? key : best),
        'bottom',
      )
    },
    handleClickOutside(event) {
      const popover = this.$refs.popoverRef
      const trigger = this.$refs.imageMarkerRef

      if (
        !(trigger && trigger.contains(event.target)) &&
        !(popover && popover.contains(event.target))
      ) {
        this.togglePopover(false)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';

@include sp-content-module {
  $marker-size-sm: 20px !default;
  $marker-size-md: 30px !default;
  $marker-size-lg: 40px !default;
  $marker-color-blue: $brand-primary;
  $marker-color-green: $brand-success;
  $marker-color-yellow: $brand-warning;
  $marker-color-red: $brand-danger;
  $marker-color-lightblue: $brand-info;
  $marker-color-white: $white;
  $marker-color-dark: $gray-dark;

  .image-marker-wrap {
    .image-marker {
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        display: inline-block;
        height: 20px;
        width: 20px;

        .icon-caret {
          display: inline-block;
          height: 20px;
          width: 20px;
          background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: 50%;
        }
      }

      // Shape
      &.circle {
        border-radius: 100%;
      }
      &.square {
        width: 100%;
        height: 100%;
      }

      // Size
      &.sm {
        height: $marker-size-sm;
        width: $marker-size-sm;
      }
      &.md {
        height: $marker-size-md;
        width: $marker-size-md;
      }
      &.lg {
        height: $marker-size-lg;
        width: $marker-size-lg;
      }

      // Color
      &.primary {
        background-color: $marker-color-blue;
      }
      &.danger {
        background-color: $marker-color-red;
      }
      &.warning {
        background-color: $marker-color-yellow;
      }
      &.success {
        background-color: $marker-color-green;
      }
      &.dark {
        background-color: $marker-color-dark;
      }
      &.info {
        background-color: $marker-color-lightblue;
      }
      &.white {
        background-color: $marker-color-white;
        color: #000000;
      }

      // Arrow
      &.up > div .icon-caret {
        transform: rotate(180deg);
      }
      &.left > div .icon-caret {
        transform: rotate(90deg);
      }
      &.right > div .icon-caret {
        transform: rotate(-90deg);
      }
      &.down > div .icon-caret {
        transform: rotate(0);
      }

      &.pulse {
        animation: image-marker-pulsate 0.75s infinite;
      }
      &.pulse.square {
        animation: image-marker-pulsate-square 0.75s infinite;
      }
      &.animate.arrow.up > div {
        animation: image-marker-arrow-up 0.75s infinite;
      }
      &.animate.arrow.right > div {
        animation: image-marker-arrow-right 0.75s infinite;
      }
      &.animate.arrow.down > div {
        animation: image-marker-arrow-down 0.75s infinite;
      }
      &.animate.arrow.left > div {
        animation: image-marker-arrow-left 0.75s infinite;
      }
      &.fade-in-out {
        animation: image-marker-fade-in-out 0.75s infinite;
      }

      @keyframes image-marker-arrow-up {
        0% {
          opacity: 0;
          transform: translate(0, 40%);
        }
        50% {
          opacity: 0.7;
        }
        100% {
          opacity: 1;
          transform: translate(0, -40%);
        }
      }
      @keyframes image-marker-arrow-right {
        0% {
          opacity: 0;
          transform: translate(-40%, 0);
        }
        50% {
          opacity: 0.7;
        }
        100% {
          opacity: 1;
          transform: translate(40%, 0);
        }
      }
      @keyframes image-marker-arrow-down {
        0% {
          opacity: 0;
          transform: translate(0, -40%);
        }
        50% {
          opacity: 0.7;
        }
        100% {
          opacity: 1;
          transform: translate(0, 40%);
        }
      }
      @keyframes image-marker-arrow-left {
        0% {
          opacity: 0;
          transform: translate(40%, 0);
        }
        50% {
          opacity: 0.7;
        }
        100% {
          opacity: 1;
          transform: translate(-40%, 0);
        }
      }
      @keyframes image-marker-pulsate {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.5);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes image-marker-pulsate-square {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.05);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes image-marker-fade-in-out {
        0% {
          opacity: 0;
        }
        50% {
          opacity: var(--opacity);
        }
        100% {
          opacity: 0;
        }
      }
    }

    .image-marker-popover {
      position: absolute;
      z-index: 999;
      padding: 8px;

      // Popover placement
      &.top {
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);

        .image-marker-popover-arrow {
          bottom: 0;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          border-width: 0 1px 1px 0;
        }
      }
      &.bottom {
        left: 50%;
        transform: translateX(-50%);

        .image-marker-popover-arrow {
          top: 0;
          left: 50%;
          transform: translate(-50%, 50%) rotate(45deg);
          border-width: 1px 0 0 1px;
        }
      }
      &.right {
        top: 50%;
        left: 100%;
        transform: translateY(-50%);

        .image-marker-popover-arrow {
          top: 50%;
          left: 0;
          transform: translate(50%, -50%) rotate(45deg);
          border-width: 0 0 1px 1px;
        }
      }
      &.left {
        top: 50%;
        left: 0;
        transform: translate(-100%, -50%);

        .image-marker-popover-arrow {
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%) rotate(45deg);
          border-width: 1px 1px 0 0;
        }
      }

      .image-marker-popover-body {
        width: max-content;
        max-width: 400px;
        padding: 6px 10px;
        font-size: 14px;
        color: #000;
        background-color: #fff;
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;

        @media (max-width: 1200px) {
          max-width: 300px;
        }
        @media (max-width: 768px) {
          max-width: 200px;
        }
      }

      .image-marker-popover-arrow {
        height: 8px;
        width: 8px;
        position: absolute;
        background-color: #fff;
        border-style: solid;
      }

      .image-marker-popover-body,
      .image-marker-popover-arrow {
        border-color: rgba($color: #000000, $alpha: 0.2);
      }
    }
  }
}
</style>
