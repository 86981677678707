<template>
  <div
    class="sp-navigation-map"
    :id="getWrapId()"
  >
    <div
      class="map"
      :class="{'with-table-of-contents': showTableOfContents}"
    >
      <div class="inm-wrap">
        <h5 class="subtitle">{{ activeScreen.props.title.value }}</h5>
        <div class="image-wrap">
          <div
            class="inner-img-wrap"
            v-if="activeScreen.props && activeScreen.props.file"
          >
            <img
              :src="activeScreen.props.file.value | cdn"
              @click="onScreenClick($event)"
            >
          </div>
          <div class="markers-wrap">
            <image-marker
              v-for="(m, i) in activeScreen.markers"
              :key="`${activeScreen.key}-${i}`"
              :id="getMarkerId(i)"
              :data="m"
              @click="onMarkerClick($event, m.action)"
            />
          </div>
        </div>

        <!-- begin: sideblock-->
        <div class="side-block">
          <div
            v-for="(m, i) in activeScreen.markers"
            :key="`${activeScreen.key}-${i}`"
            :id="getMarkerId(i)"
            :data="m"
            class="explanation-block"
            @mouseenter="activeMarker=i; allInactive=true"
            @mouseleave="activeMarker=null; allInactive=null"
            :class="{
              'active' : activeMarker == i,
              'hidden' : isHiddenTooltip(activeScreen, i),
              'inactive' : allInactive && activeMarker != i
            }"
            v-html="'<div class=\'circle-number\'><span>' + i + '</span></div>' + tooltipText(activeScreen, i)"
          />
          <!-- end: sideblock-->

          <p
            class="text-center m-0 p-3"
            v-if="activeScreen.props.caption && activeScreen.props.caption.value"
          >
            {{ activeScreen.props.caption.value }}
          </p>
        </div>
      </div>

      <div
        class="table-of-contents"
        v-if="showTableOfContents"
      >
        <h5 class="subtitle">
          {{ $t('article.table_of_contents') }}
        </h5>
        <ul class="list-group map-index">
          <li
            class="list-group-item cursor-pointer"
            :class="{
              'active sp-active-text': (index == 0 && activeScreenId == null) || activeScreenId == i.props.refId.value,
            }"
            v-for="(i, index) in tableOfContentsItems"
            :key="index"
            @click="navigateToTargetId($event, i.props.refId.value)"
          >
            <div class="text-break">
              {{ i.props.title.value || $t('article.no_title') }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { VBPopover } from 'bootstrap-vue'
import ImageMarker from '~/components/ImageMarker'
// TODO make an enum like in SP Agents (with Enum class)
const MARKER_ACTION_TYPE = {
  Image: 1,
  Url: 2,
  Article: 3
}
export default {
  props: {
    content: [String, Object],
    contentId: [String, Number],
    revisionId: [String, Number],
    language: [String, Number],
    container: { type: String, default: 'body' }
  },
  components: {
    ImageMarker
  },
  data () {
    let parsed
    if (typeof this.content === 'string') {
      parsed = JSON.parse(this.content)
    } else {
      parsed = this.content
    }

    return {
      screens: (parsed && parsed.screens) || parsed,
      activeScreenId: null,
      isLoading: true,
      activeMarker: null,
      allInactive: null
    }
  },
  computed: {
    activeScreen () {
      if (!this.activeScreenId) {
        return this.screens.find(s => s.initialScreen)
      } else {
        return this.screens.find(s => s.props.refId.value === this.activeScreenId)
      }
    },
    showTableOfContents () {
      return this.tableOfContentsItems && this.tableOfContentsItems.length > 0
    },
    tableOfContentsItems () {
      return this.screens && this.screens.filter(s => s.showInIndex)
    }
  },
  methods: {
    getWrapId () {
      return `spContentModuleINM-${this._uid}`
    },
    getMarkerId (index) {
      return `spImageMarker-${this._uid}-${index}`
    },
    getViewport () {
      return document.querySelector(this.container)
    },
    onMarkerClick (e, action) {
      this.sampleHotspot(e)
      this.sampleHeatmap(undefined, e)

      if (action && action.type) {
        switch (action.type) {
          case MARKER_ACTION_TYPE.Url:
            if (action.url) {
              window.open(action.url)
            }
            break
          case MARKER_ACTION_TYPE.Article:
            if (action.article) {
              this.$root.$emit('app::contentPreviewModal::preview', {
                item: action.article.extRef.item,
                parentId: this.contentId
              })
            }
            break
          case MARKER_ACTION_TYPE.Image:
            if (action.imageTarget) {
              this.navigateToTargetId(e, action.imageTarget)
            }
            break
          default:
        }
      }
    },
    onScreenClick (e) {
      this.sampleHeatmap(e)
      this.hideAllPopovers()
    },
    hideAllPopovers () {
      this.$root.$emit('bv::hide::popover')
    },
    navigateToTargetId (e, targetId) {
      if (!targetId) {
        return
      }

      this.activeScreenId = targetId !== -1 ? targetId : null
    },
    isHiddenTooltip (activeScreen, i) {
      return !(activeScreen.markers[i].action && Object.prototype.hasOwnProperty.call(activeScreen.markers[i].action, 'tooltip') && activeScreen.markers[i].action.tooltip)
    },
    tooltipText (activeScreen, i) {
      return (activeScreen.markers[i].action && Object.prototype.hasOwnProperty.call(activeScreen.markers[i].action, 'tooltip') && activeScreen.markers[i].action.tooltip) ? activeScreen.markers[i].action.tooltip : ''
    },
    sampleHotspot (m) {
      const activeScreenId = this.activeScreen && this.activeScreen.props.refId.value

      const { style: { top = 0, left = 0 } = {} } = m || {}
      if (activeScreenId && top && left) {
        const markerId = m.id || `${activeScreenId}-${parseInt(left)}-${parseInt(top)}`

        if (this.contentId && this.revisionId) {
          this.$sampling.spSample('hotspot', this.contentId, 1, this.revisionId, this.language, markerId)
        }
      }
    },
    sampleHeatmap (e, m = undefined) {
      const activeScreenId = this.activeScreen && this.activeScreen.props.refId.value

      let { style: { top = 0, left = 0 } = {} } = m || {}
      if (e && !m) {
        const { offsetX = 0, offsetY = 0, target } = e
        const { width = 0, height = 0 } = target && target.getBoundingClientRect()
        left = width && (offsetX * 100) / width
        top = height && (offsetY * 100) / height
      }

      if (activeScreenId && top && left) {
        const positionX = Math.round(parseFloat(left) * 100) / 100
        const positionY = Math.round(parseFloat(top) * 100) / 100

        if (this.contentId && this.revisionId) {
          this.$sampling.spSample('heatmap', this.contentId, 1, this.revisionId, activeScreenId, this.language, positionX, positionY)
        }
      }
    }
  },
  directives: {
    SpMarkerAction: {
      bind (el, binding, vnode) {
        const hide = () => {
          vnode.context.$root.$emit('bv::hide::popover')
        }
        const show = () => {
          vnode.context.$root.$emit('bv::hide::popover')
          vnode.context.$root.$emit('bv::show::popover', el.getAttribute('id'))
        }
        const hasContent = !!binding.value.content

        if (hasContent && binding.value.html) {
          binding.value.content = makeSpMarkerActionContent(binding)
          binding.value.customClass = (binding.value.customClass || '') + ' ' + 'sp-marker-action-popover'
        }
        VBPopover.bind(el, binding, vnode)

        const handlers = {
          touchstart: function (e) {
            e.preventDefault()
            if (hasContent) {
              show()
              vnode.context.$nextTick(addPopoverHandlers)
            } else {
              startMarkerAction()
            }
          },
          click: function (e) {
            e.preventDefault()
            if (hasContent) {
              show()
              vnode.context.$nextTick(addPopoverHandlers)
            } else {
              startMarkerAction()
            }
          },
          mouseenter: function (e) {
            if (hasContent) {
              // show()
            }
          },
          mouseleave: function (e) {
            if (hasContent) {
              // hide()
            }
          }
        }
        function startMarkerAction () {
          removePopoverHandlers()
          vnode.componentInstance.$emit('click')
          hide()
        }
        function addPopoverHandlers () {
          const id = el.getAttribute('aria-describedby')
          const $actions = document.querySelector(`#${id} .marker-actions`)
          if (!$actions.classList.contains('enabled')) {
            $actions.querySelector('.action-next').addEventListener('click', startMarkerAction)
            $actions.querySelector('.action-close').addEventListener('click', hide)
            $actions.classList.add('enabled')
          }
        }

        function removePopoverHandlers () {
          const id = el.getAttribute('aria-describedby')
          const $actions = document.querySelector(`#${id} .marker-actions`)
          if ($actions && $actions.classList.contains('enabled')) {
            $actions.querySelector('.action-next').removeEventListener('click', startMarkerAction)
            $actions.querySelector('.action-close').removeEventListener('click', hide)
          }
        }

        // function isTouchDevice () {
        //   return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
        // }

        // Register events
        Object.keys(handlers).forEach(x => {
          el.addEventListener(x, handlers[x], false)
        })
        // Unregister events
        el.$detachEventHandlers = function () {
          Object.keys(handlers).forEach(x => {
            el.removeEventListener(x, handlers[x])
          })
        }
      },
      componentUpdated (el, binding, vnode, oldVnode) {
        binding.value.content = makeSpMarkerActionContent(binding)
        binding.value.customClass = (binding.value.customClass || '') + ' ' + 'sp-marker-action-popover'
        VBPopover.componentUpdated(el, binding, vnode, oldVnode)
      },
      unbind (el, binding, vnode) {
        if (el) {
          el.$detachEventHandlers && el.$detachEventHandlers()
        }
        VBPopover.unbind(el, binding, vnode)
      }
    }
  }
}

function makeSpMarkerActionContent (binding) {
  const continueTitle = binding.value.continueTitle || 'Continue'
  const closeTitle = binding.value.closeTitle || 'x'
  const content = binding.value.content ? binding.value.content.replace('target="_blank"', '') : binding.value.content
  return [
    `<div class="popover-content">${content}</div>`,
    '<div class="marker-actions">',
    `<a role="button" class="btn btn-primary action-next">${continueTitle}</a>`,
    `<a role="button" class="btn btn-link action-close">${closeTitle}</a>`,
    '</div>'
  ].join('')
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .sp-navigation-map {
    position: relative;
    margin: 0 15px;
    display: flex;
    justify-content: center;

    .image-wrap,
    .markers-holder {
      position: relative;
    }
    .subtitle {
      margin: 1em 0;
      text-align: center;
    }
    .inm-wrap {
      position: relative;
      margin-bottom: 1em;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-column: span 2 / span 2;
    }
    .map {
      flex-grow: 1;
      &.with-table-of-contents {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        max-width: 1200px;
        gap: 44px 0;
      }
    }
    .image-wrap {
      .inner-img-wrap {
        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 70vh;
        }
      }
    }
    .table-of-contents {
      min-width: 200px;
    }
    .map-index {
      border: 1px solid $border-color;
      list-style: none;
      padding: 0;

      .list-group-item {
        cursor: pointer;
        border-top: 1px solid $border-color;
        padding: 0.5em 0.75em;
        font-size: $font-size-base;
        font-weight: $font-weight-normal;

        &.active {
          color: $white;
          background-color: $primary;
        }
        &:first-child {
          border-top: none;
        }
      }
    }
    .sp-marker-action-popover {
      padding: 0.5em 0.75em 0;
      width: auto;
      max-width: 400px;

      .marker-actions {
        display: none;
        padding-top: 1em;

        &.enabled {
          display: block;
          text-align: center;
        }
      }
    }
    @media screen and (min-width: $screen-md-min) {
      .map.with-table-of-contents {
        gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      .inm-wrap {
        grid-column: span 2 / span 2;
      }
    }
    @media screen and (min-width: $screen-lg-min) {
      .map.with-table-of-contents {
        gap: 40px;
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }
      .table-of-contents {
        grid-column: span 2 / span 2;
      }
      .inm-wrap {
        grid-column: span 4 / span 4;
      }
    }
  }
}
</style>
